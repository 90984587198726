<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title
          >Administrar procedimentos aceitos pelo sistema</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen">
          Adicionar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="1">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Buscar"
                          placeholder="Pesquise pelo nome do procedimento"
                          v-model="filters.search"
                        >
                        </v-text-field
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        ><v-select
                          label="Tipo: "
                          :items="[
                            { text: 'Ambos', value: null },
                            { text: 'Consultas', value: 'consultation' },
                            { text: 'Exames', value: 'exam' },
                          ]"
                          v-model="filters.type"
                        ></v-select
                      ></v-col>
                      <v-col>
                        <ExamModeSelect
                          :fromExamForm="false"
                          v-model="filters.examMode"
                        />
                      </v-col>
                      <v-col>
                        <SubspecialtySelect
                          v-model="filters.examSubspecialty"
                        />
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn
                        color="success"
                        class="mr-2"
                        @click="loadProcedures"
                      >
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir" @click="deleteExamReference(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.page"
        :length="numberOfPages"
        @input="loadProcedures"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import SubspecialtySelect from "@/components/template/FormElements/Masks/SubspecialtySelect";

export default {
  components: {
    ExamModeSelect,
    SubspecialtySelect,
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Tipo", value: "typeName" },
        { text: "Modalidade", value: "examMode" },
        { text: "Subspecialidade", value: "examSubspecialty", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        search: null,
        examMode: null,
        examSubspecialty: null,
        type: null,
        page: 1,
      },
    };
  },
  methods: {
    async loadProcedures() {
      try {
        this.loading = true;

        const params = {
          pagination: 1,
          ...this.filters,
        };

        let url = `/procedures`;

        const response = await this.$axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.typeName = item.type === "exam" ? "Exame" : "Consulta";

        item.examMode = item.ExamReference
          ? item.ExamReference.ExamMode.name
          : "-";

        item.examSubspecialty = item.ExamReference
          ? item.ExamReference.ExamSubspecialty.name
          : "-";

        return item;
      });

      this.loading = false;
    },
    async deleteExamReference(procedureId) {
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar o procedimento selecionado do sistema?"
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/procedures/${procedureId}`);

        this.deleteExamReferenceInArray(procedureId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    deleteExamReferenceInArray(procedureId) {
      const index = this.items.findIndex((item) => item.id === procedureId);

      if (index !== -1) this.items.splice(index, 1);
    },
    openUpdateScreen(procedureId) {
      this.$router.push(`/procedimentos/${procedureId}`);
    },
    openInsertScreen() {
      this.$router.push("/procedimentos/cadastrar");
    },
    clearFilters() {
      this.filters = {
        search: null,
        examMode: null,
        examSubspecialty: null,
        type: null,
        page: 1,
      };
      this.loadProcedures();
    },
  },
  created() {
    this.loadProcedures();
  },
};
</script>

<style></style>
