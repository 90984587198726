<template>
  <div>
    <v-select
      :loading="loadingExamModes"
      :value="value"
      @change="$emit('input', $event)"
      :items="examModeOptions"
      :item-value="fromExamForm ? 'text' : 'value'"
      item-text="text"
      label="Modalidade:"
    >
      <template v-if="allowCreate" v-slot:append-item>
        <v-btn class="ma-1" text small @click.stop="newExamModeRequest">
          Cadastrar
          <v-icon right color="blue">mdi-plus-circle-outline</v-icon></v-btn
        >
      </template>
    </v-select>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  props: ["value", "fromExamForm", "newExamModeOption", "allowCreate"],
  components: {},
  data() {
    return {
      loadingExamModes: false,
      examModeOptions: [],
    };
  },
  methods: {
    newExamModeRequest() {
      this.$emit("new-exam-mode");
    },
    setResponseData(data) {
      this.examModeOptions = data.map((d) => {
        return {
          value: d.id,
          text: d.name,
        };
      });

      this.loadingExamModes = false;
    },
    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModeOptions = [];

        const url = `/exam-modes`;

        const res = await this.$axios.get(url);

        // console.log(res.data);

        this.setResponseData(res.data);
      } catch (error) {
        this.errorLoadingData(error);
      } finally {
        this.loadingExamModes = false;
      }
    },
    async errorLoadingData(error) {
      const errorHandled = errorHandler.treatError(error);

      await this.$root.$errorDialog(errorHandled, {
        width: "800",
        color: "primary",
      });
    },
  },
  watch: {
    newExamModeOption(value) {
      if (!value) return;

      this.examModeOptions.push(value);
    },
  },
  created() {
    this.loadExamModes();
  },
};
</script>

<style></style>
