<template>
  <v-select
    :loading="loadingSubspecialty"
    :value="value"
    @change="$emit('input', $event)"
    :items="subspecialtyOptions"
    label="Subespecialidade:"
    hint="Escolha a subspecialidade"
  ></v-select>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      loadingSubspecialty: false,
      subspecialtyOptions: [],
    };
  },
  methods: {
    setResponseData(data) {
      this.subspecialtyOptions = data.map((d) => {
        return {
          value: d.id,
          text: d.name,
        };
      });

      this.loadingSubspecialty = false;
    },
    async loadSubspecialties() {
      try {
        this.loadingSubspecialty = true;

        this.subspecialtyOptions = [];

        const url = `/subspecialties`;

        const res = await this.$axios.get(url);

        this.setResponseData(res.data);
      } catch (error) {
        this.loadingSubspecialty = false;

        this.errorLoadingData(error);
      }
    },
    errorLoadingData(error) {
      // if (Array.isArray(error)) {
      //   error.forEach((e) => {
      //     this.$toasted.global.defaultError({ msg: `${e}` });
      //   });
      // } else {
      //   this.$toasted.global.defaultError({ msg: `${error}` });
      // }
      console.log(error);
    },
  },
  created() {
    this.loadSubspecialties();
  },
};
</script>

<style></style>
